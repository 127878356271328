<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm">
      <el-form-item>
        <el-select
          style="width: 180px; margin-right: 10px"
          v-model="dataForm.uid"
          filterable
          remote
          clearable
          reserve-keyword
          placeholder="按用户筛选"
          :remote-method="remoteManager"
          :loading="loading"
        >
          <el-option
            v-for="item in userList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="dataForm.cid"
          clearable
          :placeholder="companyName ? companyName : '按代理商筛选'"
          filterable
          @focus="companySelectFocus"
          style="width: 180px; margin-right: 10px"
          @change="changeCompany"
        >
          <el-option
            v-for="item in companyOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="dataForm.type"
          clearable
          placeholder="按类型筛选"
          style="width: 180px; margin-right: 10px"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-date-picker
          style="width: 180px; margin-right: 10px"
          v-model="dataForm.startDate"
          align="right"
          type="date"
          placeholder="选择开始日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
          >
        </el-date-picker>
        <el-date-picker
          style="width: 180px; margin-right: 10px"
          v-model="dataForm.endDate"
          align="right"
          type="date"
          placeholder="选择开始日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
          >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        prop="id"
        header-align="center"
        align="center"
        label="编号"
      >
      </el-table-column>
      <el-table-column
        prop="uid"
        header-align="center"
        align="center"
        label="推广人id"
      >
      </el-table-column>
      <el-table-column
        prop="uname"
        header-align="center"
        align="center"
        label="推广人名字"
      >
      </el-table-column>
      <el-table-column
        prop="oid"
        header-align="center"
        align="center"
        label="推广的订单id"
      >
      </el-table-column>
      <el-table-column
        prop="type"
        header-align="center"
        align="center"
        label="推广的订单类型"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.type == 0">新车</span>
          <span v-if="scope.row.type == 1">二手车</span>
          <span v-if="scope.row.type == 2">洗护产品</span>
          <span v-if="scope.row.type == 3">加油站产品</span>
          <span v-if="scope.row.type == 4">车检产品</span>
          <span v-if="scope.row.type == 5">维修产品</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="order.weixinMoney"
        header-align="center"
        align="center"
        label="订单金额"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.order.weixinMoney / 100 }} </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        label="创建时间"
      >
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center; margin-top: 10px"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100, 1000, 10000]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getPopularizeList, getUserList, getCompanyList } from "../../api/api";
export default {
  data() {
    return {
      dataForm: {
        uid: "",
        cid: "",
        type: "",
        startDate: "",
        endDate: "",
      },
      // 用户选择器列表
      userList: [],
      //  代理商选择器列表
      companyOptions: [],
      // 代理商选择器默认展示
      companyName: "",
      // 类型选择器列表
      typeOptions: [
        {
          value: 0,
          label: "新车",
        },
        {
          value: 1,
          label: "二手车",
        },
        {
          value: 2,
          label: "洗养产品",
        },
        {
          value: 3,
          label: "加油站产品",
        },
        {
          value: 4,
          label: "车检产品",
        },
        {
          value: 5,
          label: "维修产品",
        },
      ],
      loading: false,
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    // 选择代理商的事件
    changeCompany(value) {
      this.dataForm.bid = "";
      this.businessName = "";
      this.dataForm.cid = value;
    },
    // 代理商选择框获得焦点事件
    companySelectFocus() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo.cid) {
        return;
      }

      this.dataListLoading = true;
      getCompanyList({ limit: 9999 }).then((data) => {
        if (data && data.code === 0) {
          let resultList = data.data.list;
          this.companyOptions = [];
          if (resultList.length > 0) {
            for (let item of resultList) {
              this.companyOptions.push({
                value: item.id,
                label: item.name,
              });
            }
          }
        }
        this.dataListLoading = false;
      });
    },
    // 搜索用户
    remoteManager(query) {
      if (query !== "") {
        this.loading = true;
        this.userList = [];
        setTimeout(() => {
          // 请求用户列表，封装数据
          let requestData = { keyword: query };
          getUserList(requestData).then((data) => {
            if (data && data.code === 0) {
              for (let item of data.data.list) {
                let userOps = {};
                userOps.value = item.id;
                userOps.label = item.nickName + "(" + item.realName + ")";
                this.userList.push(userOps);
              }
            } else {
              this.userList = [];
            }
          });
        }, 200);
        this.loading = false;
      }
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.dataForm.page = this.pageIndex;
      this.dataForm.limit = this.pageSize;
      getPopularizeList(this.dataForm).then((data) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list;
          this.dataList.forEach((item) => {
            if (!item.order) {
              item.order = { weixinMoney: 0 };
            }
          });
          this.totalPage = data.data.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
  },
};
</script>
